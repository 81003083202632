import React, { Component } from "react";
import io from 'socket.io-client';

// Import FileUpload
import FileUpload from '../file-upload/file-upload.component';

// Material UI
import {
  Grid,
  Button,
  TextField,
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Select
} from '@material-ui/core';

class FormContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      postId: 0,
      power_strip_status: false,
      psu_1_status: false,
      psu_1_voltage: 0,
      psu_1_current: 0,
      psu_2_status: false,
      psu_2_voltage: 0,
      psu_2_current: 0,
      sine: false,
      square: false,
      sawtooth: false,
      arb: false,
      input: false,
      output: false,
      relay1: false,
      relay2: false,
      relay3: false,
      relay4: false,
      relay5: false,
      relay6: false,
      relay7: false,
      relay8: false,
      relay9: false,
      relay10: false,
      relay11: false,
      relay12: false,
      relay13: false,
      relay14: false,
      relay15: false,
      v_peak_to_peak: 0,
      v_offset: 0,
      x_div: 0,
      y_div: 0,
      trigger: 0,
      logs: []
    }
  }

  componentDidMount() {
    var socketEndpoint = "https://api.xbench.app";

    this.socket = io.connect(socketEndpoint, {
        reconnection: true,
        // transports: ['websocket']
    });

    this.socket.on("serverMsg", message => {
      console.log(message)
    });

    this.socket.on("appendBoard", message => {
      this.setState({
        logs: this.state.logs.concat({
          message
        })
      })
      console.log(this.state.logs)
    });
  }

  handleChange = (event) => {
    const { target: { name, value } } = event
    this.setState({ [name]: value })
  }

  // DISABLE ERROR
  /* eslint no-unused-vars : "off" */

  handleCheckboxChange = (event) => {
    const { target: { name, checked } } = event
    this.setState(({ [name]: checked }) => ({ [name]: !checked }))
  }

  handleEmit = (event) => {
    event.preventDefault()

    const {
      power_strip_status,
      psu_1_status,
      psu_1_voltage,
      psu_1_current,
      psu_2_status,
      psu_2_voltage,
      psu_2_current,
      sine,
      square,
      sawtooth,
      arb,
      input,
      output,
      relay1,
      relay2,
      relay3,
      relay4,
      relay5,
      relay6,
      relay7,
      relay8,
      relay9,
      relay10,
      relay11,
      relay12,
      relay13,
      relay14,
      relay15,
      v_peak_to_peak,
      v_offset,
      x_div,
      y_div,
      trigger
    } = this.state

    console.log(this.state)

    this.socket.emit("clientMsg", {
      'power_strip_status': this.state.power_strip_status,
      'psu_1_status': this.state.psu_1_status,
      'psu_1_voltage': this.state.psu_1_voltage,
      'psu_1_current': this.state.psu_1_current,
      'psu_2_status': this.state.psu_2_status,
      'psu_2_voltage': this.state.psu_2_voltage,
      'psu_2_current': this.state.psu_2_current,
      'sine': this.state.sine,
      'square': this.state.square,
      'sawtooth': this.state.sawtooth,
      'arb': this.state.arb,
      'input': this.state.input,
      'output': this.state.output,
      'relay1': this.state.relay1,
      'relay2': this.state.relay2,
      'relay3': this.state.relay3,
      'relay4': this.state.relay4,
      'relay5': this.state.relay5,
      'relay6': this.state.relay6,
      'relay7': this.state.relay7,
      'relay8': this.state.relay8,
      'relay9': this.state.relay9,
      'relay10': this.state.relay10,
      'relay11': this.state.relay11,
      'relay12': this.state.relay12,
      'relay13': this.state.relay13,
      'relay14': this.state.relay14,
      'relay15': this.state.relay15,
      'v_peak_to_peak': this.state.v_peak_to_peak,
      'v_offset': this.state.v_offset,
      'x_div': this.state.x_div,
      'y_div': this.state.y_div,
      'trigger': this.state.trigger,
    })
  }

  resetState = () => {
    window.location.reload(false);
  }

  render() {
    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid item>

            {/* Live Stream */}
            <img src="https://via.placeholder.com/600x400" alt="" />

            <Grid container>
              <Grid item xs={6}>

                {/* Power Strip */}
                <FormControl component="fieldset" className="form-control" id="power_strip_status_group">
                  <FormLabel component="legend">Power Strip</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="power_strip_status"
                          checked={this.state.power_strip_status}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Power Strip"
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
              <Grid item xs={6}>

                {/* Power Supplies */}
                <FormControl component="fieldset" className="form-control" id="psu_status_group">
                  <FormLabel component="legend">Power Supplies</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="psu_1_status"
                          checked={this.state.psu_1_status}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="PSU 1"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="psu_2_status"
                          checked={this.state.psu_2_status}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="PSU 2"
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>

                {/* PSU 1 */}
                <FormControl component="fieldset" className="form-control" id="psu_1_values">
                  <FormLabel component="legend">PSU 1</FormLabel>
                  <FormGroup>
                    <TextField
                      label="Voltage"
                      name="psu_1_voltage"
                      id="psu_1_voltage"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.psu_1_voltage}
                      onChange={this.handleChange}
                      placeholder={this.state.psu_2_voltage.toString()}
                    />
                    <TextField
                      label="Current"
                      name="psu_1_current"
                      id="psu_1_current"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.psu_1_current}
                      onChange={this.handleChange}
                      placeholder={this.state.psu_2_voltage.toString()}
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
              <Grid item xs={6}>

                {/* PSU 2 */}
                <FormControl component="fieldset" className="form-control" id="psu_2_values">
                  <FormLabel component="legend">PSU2</FormLabel>
                  <FormGroup>
                    <TextField
                      label="Voltage"
                      name="psu_2_voltage"
                      id="psu_2_voltage"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.psu_2_voltage}
                      onChange={this.handleChange}
                      placeholder={this.state.psu_2_voltage.toString()}
                    />
                    <TextField
                      label="Current"
                      name="psu_2_current"
                      id="psu_2_current"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.psu_2_current}
                      onChange={this.handleChange}
                      placeholder={this.state.psu_2_current.toString()}
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
            </Grid>

            {/* Emit Button */}
            <FormControl component="fieldset" className="form-control" id="resetBtn">
              <FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  className="btn btn-success btn-block"
                  id="sendButton"
                  onClick={this.handleEmit}
                >
                  Send
                </Button>
              </FormGroup>
            </FormControl>

          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid item>

            {/* Wave Form Display */}
            <img src="https://via.placeholder.com/600x400" alt="" />

            {/* Relays */}
            <FormControl component="fieldset" className="form-control" id="relay-group">
              <FormLabel component="legend">Relays</FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay1"
                      checked={this.state.relay1}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 1"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay2"
                      checked={this.state.relay2}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 2"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay3"
                      checked={this.state.relay3}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 3"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay4"
                      checked={this.state.relay4}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 4"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay5"
                      checked={this.state.relay5}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 5"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay6"
                      checked={this.state.relay6}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 6"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay7"
                      checked={this.state.relay7}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 7"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay8"
                      checked={this.state.relay8}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 8"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay9"
                      checked={this.state.relay9}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 9"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay10"
                      checked={this.state.relay10}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 10"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay11"
                      checked={this.state.relay11}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 11"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="relay12"
                      checked={this.state.relay12}
                      onChange={this.handleCheckboxChange}
                    />
                  }
                  label="Relay 12"
                />
              </FormGroup>
            </FormControl>

          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <Grid item>
            <Grid container>
              <Grid item xs={6}>

                {/* FGEN */}
                <FormControl component="fieldset" className="form-control" id="fgen">
                  <FormLabel component="legend">FGEN</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sine"
                          checked={this.state.sine}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Sine"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="square"
                          checked={this.state.square}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Square"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="sawtooth"
                          checked={this.state.sawtooth}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Sawtooth"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="arb"
                          checked={this.state.arb}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Arb"
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
              <Grid item xs={6}>

                {/* Input/Output */}
                <FormControl component="fieldset" className="form-control" id="input-output">
                  <FormLabel component="legend">I/O</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="input"
                          checked={this.state.input}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Input"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="output"
                          checked={this.state.output}
                          onChange={this.handleCheckboxChange}
                        />
                      }
                      label="Output"
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6}>

                {/* Trigger */}
                <FormControl component="fieldset" className="form-control" id="trigger">
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    <TextField
                      label="Trigger"
                      name="trigger"
                      id="trigger"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.trigger}
                      onChange={this.handleChange}
                      placeholder={this.state.trigger.toString()}
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
              <Grid item xs={6}>

                {/* V Peak to Peak and V Offset */}
                <FormControl component="fieldset" className="form-control" id="v-peak-offset">
                  <FormLabel component="legend"></FormLabel>
                  <FormGroup>
                    <TextField
                      label="V Peak to Peak"
                      name="v_peak_to_peak"
                      id="v_peak_to_peak"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.v_peak_to_peak}
                      onChange={this.handleChange}
                      placeholder={this.state.v_peak_to_peak.toString()}
                    />
                    <TextField
                      label="V Offset"
                      name="v_offset"
                      id="v_offset"
                      variant="outlined"
                      color="primary"
                      type="number"
                      min="0"
                      max="40"
                      step="0.01"
                      value={this.state.v_offset}
                      onChange={this.handleChange}
                      placeholder={this.state.v_offset.toString()}
                    />
                  </FormGroup>
                </FormControl>

              </Grid>
            </Grid>

            {/* Arb File Upload */}
            <FormControl component="fieldset" className="form-control" id="arbBtn">
              <FormLabel component="legend">Arb File</FormLabel>
              <FormGroup>
                  <FileUpload />
              </FormGroup>
            </FormControl>

            <Grid container spacing={1}>
              <Grid item xs={6}>

                {/* Xdiv */}
                <FormControl component="fieldset" className="form-control">
                  <FormLabel component="legend">Xdiv</FormLabel>
                  <Select
                    native
                    variant="outlined"
                    name='x_div'
                    id='x_div'
                    value={this.state.x_div}
                    onChange={this.handleChange}
                  >
                    <option value="Select One">Select</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </Select>
                </FormControl>

              </Grid>
              <Grid item xs={6}>

                {/* Ydiv */}
                <FormControl component="fieldset" className="form-control">
                  <FormLabel component="legend">Ydiv</FormLabel>
                  <Select
                    native
                    variant="outlined"
                    name='y_div'
                    id='y_div'
                    value={this.state.y_div}
                    onChange={this.handleChange}
                  >
                    <option value="Select One">Select</option>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                  </Select>
                </FormControl>

              </Grid>
            </Grid>

            {/* Reset Button */}
            <FormControl component="fieldset" className="form-control" id="resetBtn">
              <FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  type="button"
                  className="btn btn-success btn-block"
                  id="resetButton"
                  onClick={this.resetState}
                >
                  Reset
                </Button>
              </FormGroup>
            </FormControl>

          </Grid>
        </Grid>

        {/* Logs */}
        <div id="logs">
          <p>Logs (returned from socketserver):</p>
          <ul className="list-group list-group-flush" id="messages">
            {this.state.logs.map((post, index) => (
              <li key={index} id={'message-'+index}> {post.message} </li>
            ))}
          </ul>
        </div>

      </Grid>
    );
  }
}

export default FormContainer;
